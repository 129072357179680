body {
    margin: 0;
    background-color: black;
    color: white;
    font-family: monospace;
}

canvas {
  display: block;
  width: 100vw;
  height: 100vh;
  cursor: crosshair;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.absolute {
  position: absolute;
  left: 0;
  top: 0;
}

.score {
  padding-top: 10px;
}

.sector {
  padding-top: 50px;
}

.minimap {
  padding-top:30px
}

